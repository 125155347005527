<template>
  <a-spin :spinning="loading">
    <div style="padding: 20px 10% 20px 10%">
      <a-card :bordered="false" style="margin-bottom: 20px">
        <template>
          <h1 style="text-align: center" slot="title">
            {{ $i18n.locale === 'enUS' ? info.nameEn : info.name }}
          </h1>
        </template>

        <div style="text-align: center">
          <a-button type="primary" size="large" @click="dowloadClicked">
            {{ $t('message')['public.resource.download'] }}
          </a-button>
        </div>
      </a-card>

      <div class="footer">
        <a-row type="flex">
          <a-col
            v-if="company"
            :span="12"
            :offset="0"
            style="text-align: center"
          >
            <img src="@/assets/shop-qr.png" class="footer-qr-img" />
            <p>{{ $t('message')['company.qr.shop'] }}</p>
          </a-col>

          <a-col v-if="company" :span="12" style="text-align: center">
            <img class="footer-qr-img" src="@/assets/download-qr.png" />
            <p style="margin-left: 13px">
              {{ $t('message')['company.qr.app'] }}
            </p>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { dataZH, dataEN } from '@/utils/data'
import { getLanguage } from '@/utils/languageUtils'
import { getPublicResourceDetail } from '@/api/public-resource'
export default {
  name: 'PublicResourceDetail',
  data() {
    return {
      loading: false,
      id: '',
      info: {
        name: '',
        nameEN: '',
      },
      baseInfo: dataZH.baseInfo,
      company: dataZH.company,
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.loading = true
      getPublicResourceDetail(this.id)
        .then((res) => {
          if (res.code === 200) {
            this.info = res.data
          } else if (res.code === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            // 失败
            this.$router.push({
              path: '/404',
              query: { locale: getLanguage(this.$route) },
            })
          } else {
            this.$message.error(err.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 更改语言
    changeLanguage(language) {
      this.baseInfo = language === 'enUS' ? dataEN.baseInfo : dataZH.baseInfo
      this.company = language === 'enUS' ? dataEN.company : dataZH.company
    },
    // 下载
    dowloadClicked() {
      window.open(
        getLanguage(this.$route) === 'enUS'
          ? this.info.dataResourceUrlEn
          : this.info.dataResourceUrl
      )
    },
  },
}
</script>

<style scoped>
.footer {
  margin-top: 120px;
}
/* 底部二维码 */
.footer-qr-img {
  width: 100px;
  height: 100px;
}
</style>
