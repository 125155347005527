import request from '@/utils/request'

const pubApi = {
  PublicResourceDetail: '/website/public/resource/data/',
}

// 获取公共资源详情
export function getPublicResourceDetail(id) {
  return request({
    url: pubApi.PublicResourceDetail + id,
    method: 'get',
  })
}
